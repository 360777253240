import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/newImg/icons/modal-close.svg'


const _hoisted_1 = { class: "mobile-menu-container" }
const _hoisted_2 = { class: "mobile-menu-wrapper" }
const _hoisted_3 = { class: "tab-content" }
const _hoisted_4 = { class: "mobile-nav" }
const _hoisted_5 = { class: "mobile-menu" }
const _hoisted_6 = { class: "mobile-cats-nav" }
const _hoisted_7 = { class: "mobile-cats-menu" }
const _hoisted_8 = { class: "item-cats-lead" }

import { ref, onMounted, computed } from "vue";
import Tabs from "@/components/elements/Tabs.vue";
import { mobileMenu, hideMobileMenu } from "@/utilities/common";
import { useRouter, useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileMenu',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const inputTooltipMsg = ref("Пожалуйста, заполните это поле");
const searchInput = ref<HTMLInputElement | null>(null);
const tabsData = ref([
  {
    id: "mobile-categories",
    title: "Потратить бонусы",
    active: true,
  },
  {
    id: "mobile-nav",
    title: "Меню",
  },
]);
const searchTerm = ref("");

const currentPath = computed(() => {
  return route.path;
});

onMounted(() => {
  mobileMenu();
});

const submitSearchForm = async () => {
  if (searchTerm.value.length < 2) {
    inputTooltipMsg.value = "Пожалуйста, введите минимум два символа";
    searchInput.value?.blur();
    searchInput.value?.focus();
  } else {
    await router.push({
      name: "GoodsSearch",
      query: { search: searchTerm.value },
    });
    searchTerm.value = "";
    searchInput.value?.blur();
    hideMobileMenu();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "mobile-menu-close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(hideMobileMenu) && _unref(hideMobileMenu)(...args)))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)
      ])),
      _createVNode(Tabs, {
        class: "nav-pills-mobile text-center",
        data: tabsData.value
      }, null, 8, ["data"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["tab-pane fade", { show: tabsData.value[1].active, active: tabsData.value[1].active }]),
          id: "mobile-nav"
        }, [
          _createElementVNode("nav", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", {
                class: _normalizeClass(currentPath.value === '/' ? 'active' : '')
              }, [
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "sf-with-ul",
                  onClick: _unref(hideMobileMenu)
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Главная")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ], 2),
              _createElementVNode("li", {
                class: _normalizeClass(
                  currentPath.value === '/shop/catalogue/admitads' ? 'active' : ''
                )
              }, [
                _createVNode(_component_router_link, {
                  to: {
                    name: 'AdmitadsStatic',
                  },
                  onClick: _unref(hideMobileMenu)
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Спецпредложения")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ], 2),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "http://vamprivet.ru",
                  class: "sf-with-ul",
                  target: "_blank",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(hideMobileMenu) && _unref(hideMobileMenu)(...args)))
                }, "Акции")
              ])
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-pane fade", { show: tabsData.value[0].active, active: tabsData.value[0].active }]),
          id: "mobile-categories"
        }, [
          _createElementVNode("nav", _hoisted_6, [
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", {
                class: _normalizeClass(["item-cats-lead", 
                  currentPath.value === '/shop/catalogue/certificates' ? 'active' : ''
                ])
              }, [
                _createVNode(_component_router_link, {
                  to: {
                    name: 'CertificatesStatic',
                    query: {
                      specialFilter: 'Popular',
                    },
                  },
                  onClick: _unref(hideMobileMenu)
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Подарочные сертификаты")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ], 2),
              _createElementVNode("li", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: {
                    name: 'Travels',
                  },
                  class: "sf-with-ul",
                  onClick: _unref(hideMobileMenu)
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Путешествия")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("li", {
                class: _normalizeClass(currentPath.value === '/shop/catalogue/goods' ? 'active' : '')
              }, [
                _createVNode(_component_router_link, {
                  to: {
                    name: 'GoodsStatic',
                    query: {
                      specialFilter: 'Popular',
                    },
                  },
                  onClick: _unref(hideMobileMenu)
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Товары")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ], 2),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: {
                    name: 'Charities',
                  },
                  onClick: _unref(hideMobileMenu)
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Благотворительность ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])
        ], 2)
      ])
    ])
  ]))
}
}

})